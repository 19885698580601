import { useState, useEffect } from 'react';

const useForm = (callback, validate) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    updateKeyValues(event.target.name, event.target.value);
  };

  const updateKeyValues = (name, value) => {
    const changedValues = { ...values, [name]: value };
    if(errors[name]) {
      setErrors(validate(changedValues));
    }
    setValues(changedValues);
  };

  return {
    handleChange,
    handleSubmit,
    updateKeyValues,
    setValues,
    values,
    errors,
  }
};

export default useForm;