import React, { Fragment } from 'react';
import Hero from '../components/hero';
import ContactForm from '../components/contact-form';
import SEO from '../components/seo';

export default function Contact() {
  return (
    <Fragment>
      <SEO title="Contact" />
      <Hero title="Contact" colors={['#f00', '#fc0']} />
      <div className="container mx-auto max-w-md">
        <ContactForm />
      </div>
    </Fragment>
  )
}

