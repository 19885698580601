import React, { Fragment, useState } from 'react';
import CloseIcon from '../svg/close.svg';
import fetch from 'isomorphic-unfetch';
import useForm from '../utils/useForm';
import queryString from 'query-string';
import validate from '../utils/ContactFormValidationRules';
import '../scss/form.scss';

export default function ContactForm() {
  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit
  } = useForm(() => send(), validate);

  const [ formComplete, setFormComplete ] = useState(false);
  const inputClasses = 'bg-transparent outline-none w-full appearance-none border rounded-lg text-md rounded-lg p-3 border-style-solid placeholder-white border-white';  
  const buttonClasses = 'appearance-none py-2 outline-none rounded-full focus:outline-none tracking-wide min-h-sm';  
  const errorClasses = `bg-dark-grey border-red-400 text-red-400 absolute top-0 left-0 pointer-events-none`;

  const send = async () => {
    const res = await fetch('/php/contact.php', {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded'
      },
      body: queryString.stringify(values)
    });
    const data = await res.json();
    if(data.success) {
      setFormComplete(true);
      setValues({});
    }
  }
  
  const handleFocus = (event) => {
    event.target.nextSibling && event.target.nextSibling.classList.add('hidden');
  };

  const handleBlur = (event) => {
    event.target.nextSibling && event.target.nextSibling.classList.remove('hidden');
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      setFormComplete(false);
    }
  }

  return (
    <Fragment>
      <form className="block text-white" onSubmit={handleSubmit} noValidate>
        <div className="mb-4 relative">
          <input name="name" onChange={handleChange} placeholder="Your name *" className={`${inputClasses}`} value={values.name || ''} />
          {errors.name && (
            <div className={`${inputClasses} ${errorClasses}`}>{errors.name}</div>
          )}
        </div>
        <div className="mb-4 relative">
          <input name="email" onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} placeholder="Your email *" className={`${inputClasses}`} value={values.email || ''} />
          {errors.email && (
            <div className={`${inputClasses} ${errorClasses}`}>{errors.email}</div>
          )}
        </div>
        <div className="mb-4 relative">
          <textarea name="message" onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} placeholder="Your message *" className={`${inputClasses} form-textarea resize-none`} value={values.message || ''} />
          {errors.message && (
            <div className={`${inputClasses} form-textarea ${errorClasses}`}>{errors.message}</div>
          )}
        </div>
        <div className="text-center">
          <button type="submit" className={`${buttonClasses} cursor-pointer leading-none text-lg w-full md:w-auto px-20 bg-white hover:bg-gray-300 text-black transition-all`}>Send</button>
        </div>
      </form>
      {formComplete && (
        <div tabIndex={0} role="textbox" onClick={() => setFormComplete(false)} onKeyDown={handleKeyDown} className="fixed inset-0 bg-transparent-black flex items-center justify-center">
          <div className="color-white pointer-events-none text-center bg-dark-grey p-20 relative">
            <CloseIcon className="absolute top-15 right-15" style={{height: `20px`}} />
            <div className="text-4xl font-bold" >Thank You</div>
            <div className="text-2xl">Your message has been sent.</div>
          </div>
        </div>
      )}
    </Fragment>
  )
}
